import React from "react"
import { graphql } from "gatsby"
import { Page, PICard, YTVideo } from "../../components/elements"
import { PageCenter } from "../../components/elements/wrappers/PageCenter.jsx"
import { getYTID } from "../../components/tools"
import { Row } from "react-bootstrap"

export default function Template({
    data: {
        video: {
            frontmatter: { title, url, description, PI },
            html,
        },
        members: { edges: members },
    },
}) {
    let contact = {}

    members.forEach(({ node: { frontmatter: fm } }) => {
        contact[fm.slug] = fm
    })

    return (
        <Page title={title} description={description}>
            <PageCenter>
                <h2 className="border-bottom pb-3 mb-4">{title}</h2>
                <YTVideo
                    title={title}
                    src={`https://www.youtube.com/embed/${getYTID(
                        url,
                    )}?feature=oembed`}
                />
                {PI ? (
                    <Row>
                        {PI.map((e, i) => {
                            return <PICard key={i} member={contact[e]} />
                        })}
                    </Row>
                ) : null}
                {html ? (
                    <>
                        <h5 className="border-bottom pb-3 mt-5">
                            More Information
                        </h5>
                        <Row>
                            <div
                                className="p-4"
                                dangerouslySetInnerHTML={{
                                    __html: html,
                                }}
                            ></div>
                        </Row>
                    </>
                ) : null}
            </PageCenter>
        </Page>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        video: markdownRemark(frontmatter: { title: { eq: $title } }) {
            ...VideoFields
        }
        members: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "//members//" } }
        ) {
            edges {
                node {
                    ...MemberFields
                }
            }
        }
    }
`
